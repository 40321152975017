import { CurrentPlanModel } from '../../../../domain/models'
import customTheme from '../../../../styles/theme'
import { Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import {
  ButtonRedGreen,
  ButtonRedOutlineGreen,
  Typography
} from '../../foundation'
import React, { useState } from 'react'
import { enumFrequency } from '../../../../utils/enums/frequency'
import { enumStatus } from '../../../../utils/enums/status'
import { makeCancelPlan } from '../../../../main/factories/usecases/subscriptions/current-plans'
import { useAlertBox } from '../alert-box/alert-box-context'
import { useHistory } from 'react-router'

interface CardMyPlanItemProps {
  plan: CurrentPlanModel
  handleReloadPage: () => void
}
export const CardMyPlanItem = ({ plan, handleReloadPage }: CardMyPlanItemProps): JSX.Element => {
  const alertContext = useAlertBox()
  const history = useHistory()
  const [showReasonField, setShowReasonField] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)

  const reasonList = ['Saúde', 'Financeiro', 'Não gostei dos conteúdos', 'Opção pelo presencial', 'Sem tempo', 'Sem motivação', 'Outros']

  const handleCancelPlan = async (e) => {
    e.preventDefault()
    if (e.target['reason-selected'].value) {
      let reason = e.target['reason-selected'].value
      if (e.target?.reason?.value) reason = `${reason} - ${e.target?.reason?.value}`

      /* setModal(false)
      const data = await makeCancelPlan(plan.id, reason)
      handleReloadPage()
      alertContext.alertBox(data) */
      window.location.href='https://web.whatsapp.com/send?phone=5521993343580&text=Cancelamento de matricula. Motivo: ' + reason
    } else {
      alert('Informe o motivo do seu cancelamento.')
    }
  }

  // so mostrar mudanda de plano na frequencia mensal
  // plans?status=active&order[position]=asc&intervalCount=6or12 endpoint

  return (
    <>
      <Box
        border="1px solid #313131"
        borderRadius="10px"
        p={8}
      >
        <Typography type="2xl" as="h2" isTruncated>Assinatura: {plan.planName}</Typography>
        <p>Status: <b>{enumStatus(plan.status)}</b></p>
        <p>Periodicidade: <b>{enumFrequency(plan.frequency)}</b></p>
        {plan.status !== 'canceled' && (
          <ButtonBox>
            <ButtonRedGreen type="submit" onClick={() => history.push('/alterar-plano')}> Alterar Plano </ButtonRedGreen>
            <ButtonRedOutlineGreen className='ml-3' type="submit" onClick={() => setModal(true)}> Cancelar </ButtonRedOutlineGreen>
          </ButtonBox>
        )}
      </Box>

      {modal && (
        <>
          <ModalBg />
          <Modal>
            <Box px={8} py={16} textAlign="center">
              <form onSubmit={handleCancelPlan}>
                <Typography type="lg" mb={8}>Você tem certeza que deseja cancelar o plano <b>{plan.planName}</b>?</Typography>
                <SelectCustom name='reason-selected' required onChange={(e) => {
                  if (e.target.value === 'Outros') {
                    setShowReasonField(true)
                  } else {
                    setShowReasonField(false)
                  }
                }}>
                  <option value=''>Selecione um motivo</option>
                  {reasonList.map((item, idx) => (
                    <option key={idx} value={item}>{item}</option>
                  ))}
                </SelectCustom>
                {showReasonField && <CustomIpnut name='reason' placeholder="Por favor, informe o motivo do cancelamento" />}
                <Flex justifyContent="center">
                  <ButtonRedGreen type="button" mr={4} onClick={() => setModal(false) }>NÃO</ButtonRedGreen>
                  <ButtonRedOutlineGreen type="submit" >SIM</ButtonRedOutlineGreen>
                </Flex>
              </form>
            </Box>
            <CloseButton onClick={() => setModal(false)}>x</CloseButton>
          </Modal>
        </>
      )}
    </>
  )
}

const CustomIpnut = styled.textarea`
  border: 1px solid ${customTheme.colors.gray['300']};
  width: 50.01%;
  max-width: 100%;
  margin-bottom: 15px;
  height: 80px;
  padding: 5px;

  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    width: 80%;
  }
`

export const SelectCustom = styled.select`
  background-color: ${customTheme.colors.white};
  border: 1px solid ${customTheme.colors.gray['200']};
  width: 50%;
  margin-bottom: 15px;
  height: 38px;
  padding: 0 20px;
  font-size: ${customTheme.fontSizes.md};
  border-radius: ${customTheme.radii.lg};
  transition: all .3s ease;

  &:focus {
    border-color: ${customTheme.colors.green['700']};
  }
  &:hover {
    border-color: ${customTheme.colors.gray['300']};
  }

  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    width: 82%;
  }
`

const ModalBg = styled.div`
  background: rgb(0 0 0 / 70%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
`

const Modal = styled.div`
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  aspect-ratio: 16/9;
  max-width: 90%;
  background: #fff;
`

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 28px;
  font-family: monospace;
  width: 30px;
  background: #ddd;
  line-height: 26.5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  z-index: 99;
  display: block;
`

const ButtonBox = styled.div`
  button {
    margin-right: 20px
  }
`
