import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'

import bg from '../../assets/images/bgs/bg-0.jpg'
import { Container, Typography } from '../../component/ui/foundation'
import ContentView from '../../component/content-view/content-view'

import Loading from '../../component/loading/loading'
import { makeContents } from '../../main/factories/usecases/current-customer/contents'
import { enumDayToNumber } from '../../utils/enums/week-day'
import { Redirect } from 'react-router'
import { ReactComponent as AngleRightLight } from '../../component/ui/icons/angle-right-light.svg'
import { ReactComponent as AngleDownLight } from '../../component/ui/icons/angle-down-light.svg'
import { ContentModel } from '../../domain/models'
import { makeComments } from '../../main/factories/usecases/current-customer/comments'

const ViewTraining = ({ match }): JSX.Element => {
  const { slug, weekday } = match.params
  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [data, setData] = useState<ContentModel[]>(null)
  const [currentCourse, setCurrentCourse] = useState<number>(0)

  const handleChangeCourse = async (index: number) => {
    if (currentCourse !== index) {
      setRefresh(true)
      setCurrentCourse(index)
      await makeComments(data[index].id)
      setRefresh(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const options = (weekday === 'view') ? {
          slug: slug
        } : {
          publishAtWeekDay: enumDayToNumber(weekday),
          'folder.slug': slug
        }
        const content = await makeContents(options)

        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [slug, weekday])

  if (loading) {
    return <Loading/>
  }

  if (!data.length) {
    return <Redirect to="/404" />
  }

  return (
    <Box
      background={`url(${bg}) no-repeat top center`}
      backgroundSize='cover'
      py={['45px', '45px', '90px']}
    >
      <Container>
        <Box p={6} bg="white">
          <Typography type="3xl" as="h1" color="gray.700">{data[currentCourse].folder.title}</Typography>
          <hr/>

          {data.length > 1 && data.map((content, index) => (
            <Flex
              key={content['@id']}
              alignItems="center"
              cursor="pointer"
              border="1px solid #313131"
              borderColor={'purple.500'}
              borderRadius="4px"
              mb={2}
              px={4}
              bg={currentCourse === index ? 'purple.500' : 'white' }
              color={currentCourse === index ? 'white' : 'purple.500'}
              onClick={() => handleChangeCourse(index)}
            >
              <Typography type="lg" as="h2" mb={0} color={currentCourse === index ? 'white' : 'purple.500'}>
                {content.title.toUpperCase()}
              </Typography>
              <Box ml="auto">
                {currentCourse !== index ? (
                  <AngleRightLight />
                ) : (
                  <AngleDownLight />
                )}
              </Box>
            </Flex>
          ))}

          {refresh ? (
            <Loading />
          ) : (
            <ContentView
              content={data[currentCourse]}
              removeTitle={data.length !== 1}
            />
          )}

        </Box>
      </Container>
    </Box>
  )
}

export default ViewTraining
