import React from 'react'
import styled from 'styled-components'
import form from './scripts/form_20230711.json'
import { submitToGoogleForms } from './utils/googleForms'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import ShortAnswerInput from './components/short-answer-input'
import LinearGrid from './components/linear-grid'
import CheckboxInput from './components/checkbox-input'
import LongAnswerInput from './components/long-answer-input'
import HiddenInput from './components/hidden-input'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty } from '../../utils/helpers'
import { ButtonRedGreen } from '../../component/ui/foundation'
import { settings } from '../../config/settings'
import { useHistory } from 'react-router'
import { getUser } from '../../utils/helpers/user'

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0;
`

const QuestionContainer = styled.div`
  margin-bottom: 20px;
`

const QuestionLabel = styled.h3`
  margin-bottom: 10px;
`

const QuestionHelp = styled.p`
  font-size: 0.8rem;
  color: #5c5c5c;
  margin-top: 0px;
`

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 15px;
`

const Questions = () => {
  const user = getUser()

  return (
    <div>
      {form.fields.map((field) => {
        const { id } = field
        const values:any = {}

        // force user name
        if (id === '563514624') {
          field.type = 'HIDDEN'
          values[id] = user.user.name
        }

        let questionInput = null
        let questionInputRaw = null
        switch (field.type) {
          case 'CHECKBOX':
            questionInput = <CheckboxInput field={field}/>
            break
          case 'RADIO':
            // questionInput = <RadioInput id={id} />
            break
          case 'SHORT_ANSWER':
            questionInput = <ShortAnswerInput field={field} />
            break
          case 'LONG_ANSWER':
            questionInput = <LongAnswerInput field={field}/>
            break
          case 'RADIO_GRID':
            // questionInput = <RadioGridInput id={id} />
            break
          case 'CHECKBOX_GRID':
            // questionInput = <CheckboxGridInput id={id} />
            break
          case 'DROPDOWN':
            // questionInput = <DropdownInput id={id} />
            break
          case 'LINEAR':
            questionInput = <LinearGrid field={field}/>
            break
          case 'HIDDEN':
            questionInputRaw = <HiddenInput field={field} value={values[id]} />
            break
        }

        if (questionInputRaw) {
          return (
            <QuestionContainer key={id}>
              {questionInputRaw}
            </QuestionContainer>
          )
        }

        if (!questionInput) {
          return null
        }

        return (
          <QuestionContainer key={id}>
            <QuestionLabel>{field.label}</QuestionLabel>
            {questionInput}
            <QuestionHelp>{field.description}</QuestionHelp>
          </QuestionContainer>
        )
      })}
    </div>
  )
}

const Research_20230711: React.FC = () => {
  const history = useHistory()
  const methods = useForm()
  const errors = methods.formState.errors
  const { alertBox }: AlertBoxContextInterface = useAlertBox()

  const onSubmit = (data, e) => {
    e.preventDefault()
    submitToGoogleForms(form, data).then(() => {
      alertBox('Pesquisa Enviada com Sucesso!<br /><br /> Obrigado pela participação!', history.replace(settings.dashboardRoute))
    }).catch(() => {
      alertBox('Ocorreu um erro ao enviar a pensquisa. Pode ser uma instabilidade temporária de sua internet! Tente novamente mais tarde. ', history.replace(settings.dashboardRoute))
    })
  }

  return (
    <>
      <FormProvider {...methods} >
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Questions />
          { !isEmpty(errors) && <ErrorMessage>Existem erros no formulário de pesquisa. Verifique antes de enviar as respostas.</ErrorMessage> }
          <ButtonRedGreen type="submit">Enviar</ButtonRedGreen>
        </Form>
      </FormProvider>
    </>
  )
}

export default Research_20230711
