import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { Container, Typography } from '../../component/ui/foundation'
import customTheme from '../../styles/theme'
import styled from '@emotion/styled/macro'
import FolderContent from '../../component/folder-content/folder-content'
import { FolderContentModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makeCurrentCustomerFolderContents } from '../../main/factories/usecases/current-customer/folder-contents'
import bg0 from '../../assets/images/bgs/bg-0.jpg'
import bg1 from '../../assets/images/bgs/bg-1.jpg'
import bg2 from '../../assets/images/bgs/bg-2.jpg'

const AllTraining = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<FolderContentModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeCurrentCustomerFolderContents({ perPage: 30 })
        setData(content)
        setLoading(false)
      } catch (_e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading/>
  }

  const getFolders = (data: any[]) => {
    if (data && data.length > 0) {
      return data.map((item) => {
        return (
          <FolderContent item={item} view={(item.folder.id >= 27)} key={item['@id']}/>
        )
      })
    } else {
      return (
        <Box py={16}>
          <Container>
            <Typography type="lg" as="p">Você não possui nenhum treino ativo</Typography>
          </Container>
        </Box>
      )
    }
  }

  return (
    <>
      <div>
        <Box>
          <Container>
            <Typography lineHeight={5} type="4xl" as="h1" textAlign="center" textTransform="uppercase">Todos os Treinos</Typography>
          </Container>
        </Box>
        <BoxFolderItems>
          {getFolders(data)}
        </BoxFolderItems>
      </div>
    </>
  )
}

const BoxFolderItems = styled.div`
  > div {
    padding: 45px 0;
    @media (min-width: ${customTheme.breakpoints[2]}) {
      padding: 90px 0;
    }
    background: ${`url(${bg0}) no-repeat top center`};
    background-size: cover;

    &:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
        margin: -90px 0;
      }
    }

    &:nth-of-type(even) {
      background-image: ${`url(${bg2})`};
    }

    &:nth-of-type(5) {
      background-image: none;
      background-color: ${customTheme.colors.gray['700']};

      h2 {
        color: #ffffff;

        + div > p {
          color: #ffffff;
        }
      }
    }

    &:first-of-type {
      background-image: ${`url(${bg1})`};
    }

    &:last-of-type:nth-of-type(odd) {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        margin-bottom: 0;
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
`

export default AllTraining
