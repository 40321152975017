import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'
import { PlanDetailModel, PlanModel } from '../../../../domain/models'
import { getImage } from '../../../../utils/helpers/get-image'
import { addLocalStorage, removeLocalStorage } from '../../../../infra/storage/localStorage'

export const makePlans = async (sop?: any[]): Promise<PlanModel[]> => {
  try {
    const hasSop = typeof sop !== 'undefined' && sop !== null
    const requestConfig = {
      params: {
        'order[position]': 'asc',
        status: 'active'
      }
    }
    if (hasSop) { delete requestConfig.params.status }

    const response: AxiosResponse = await api.get('/plans', requestConfig)
    let respData = response.data['hydra:member']
    if (hasSop) {
      respData = respData.filter(function (el) {
        return sop.includes(el.id)
      })
    }

    return respData
      .map((plan) => ({
        ...plan,
        image: getImage(plan.image)
      }))
  } catch (e) {
    return []
  }
}

export const makeChangeablePlans = async (): Promise<PlanModel[]> => {
  try {
    const response: AxiosResponse = await api.get('/current_customer_changeable_plans', {
      params: {
        status: 'active',
        'order[position]': 'asc'
      }
    })

    return response.data['hydra:member']
      .map((plan) => ({
        ...plan,
        image: getImage(plan.image)
      }))
  } catch (e) {
    return []
  }
}

export const makePlan = async (planId: number): Promise<PlanDetailModel> => {
  try {
    const response: AxiosResponse = await api.get(`/plans/${planId}`, {
      params: {
        status: 'active'
      }
    })

    const groupItems = {}
    response.data.planItems.forEach(group => {
      if (group.productGroup) {
        Object.assign(groupItems, {
          [group.productGroup['@id']]: group.productGroup
        })
      }
    })

    return ({
      ...response.data,
      image: getImage(response.data.image),
      groupItems
    })
  } catch (e) {
    return null
  }
}

export const filterPlans = (plans, planPeriod = 'yearly'): PlanModel[] => {
  return plans.filter((plan) => plan.frequency === planPeriod)
}

export const verifyCoupon = async (code: string, plan: string): Promise<any> => {
  removeLocalStorage('coupon')
  try {
    const response = await api.post('/coupons/validate', {
      code,
      plan
    })
    await addLocalStorage('coupon', JSON.stringify(response.data))
    return response.data
  } catch (e) {
    return null
  }
}
