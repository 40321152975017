import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Avatar, Box, Flex, FlexProps, Image } from '@chakra-ui/core'
import styled from '@emotion/styled/macro'
import { ButtonGreen, ButtonRedBlack } from '../ui/foundation'
import customTheme from '../../styles/theme'
import smoothScroll from 'smoothscroll-polyfill'

import { ReactComponent as BarLight } from '../ui/icons/bars-light.svg'
import { makeNav } from '../../main/factories/usecases/auth/make-nav'
import { getUser } from '../../utils/helpers/user'
import { MenuModel } from '../../domain/models/menu'
import Linker from '../linker/linker'
import { RenderIcon } from '../ui/icons/render-icon'
import { settings } from '../../config/settings'
import Logo from '../../assets/images/logo.png'
import SearchButton from '../search/search'
import PopUpNotification from '../popup'
import { outdatedCache } from '../../utils/helpers/storage/date'
import { getLoggedMenu, setLoggedMenu } from '../../utils/helpers/storage/menu'

type CustomBoxProps = FlexProps & {
  toggle: boolean
}

const NavBar = (): JSX.Element => {
  const router = useHistory()
  smoothScroll.polyfill()
  const [toggle, setToggle] = useState<boolean>(false)
  const [menu, setMenu] = useState<MenuModel>(null)
  const user = getUser()

  const handleClick = () => {
    setToggle(prev => !prev)
  }

  const goToSession = (session: string): void => {
    if (window.location.pathname !== '/') {
      router.replace('/')
    } else {
      document.querySelector(session).scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    (async () => {
      if (getUser() && router.location.pathname !== '/') {
        const isValid = await outdatedCache()
        const loggedMenu = getLoggedMenu()
        if (isValid || !loggedMenu) {
          const data: MenuModel = await makeNav('menu')
          await setLoggedMenu(data)
          setMenu(data)
        } else {
          setMenu(loggedMenu)
        }
      }
    })()
    return () => {
      setMenu(null)
    }
  }, [router])

  return (
    <>
      <PopUpNotification />
      <Box w={['10%']}>
        <Link to={!menu ? '/' : settings.dashboardRoute}>
          <Image src={Logo} ignoreFallback alt="Gaiz" py={4} maxW={91}/>
        </Link>
      </Box>
      <Box w={['90%']}>
        <CustomFlex flexDir="row-reverse" className={toggle ? 'open' : ''}>
          <Icon ml="auto" onClick={handleClick}>
            <BarLight />
          </Icon>
          <CustomBox toggle={toggle}>

            {!menu ? (
              <>
                <span onClick={() => goToSession('#block-2')}>Valores da marca</span>
                <span onClick={() => goToSession('#block-4')}>Nossas atividades</span>
                <span onClick={() => goToSession('#block-10')}>Planos</span>
                <span onClick={() => goToSession('#block-6')}>Depoimentos</span>
                <span onClick={() => goToSession('#block-7')}>Parceiros</span>
              </>
            ) : menu.menuItems.map(item => (
              <Linker key={item['@id']} to={item.url} action={item.target} className={item.icon ? 'hasIcon' : ''}>
                <RenderIcon name={item.icon} />
                {item.title}
              </Linker>
            ))}
            {router.location.pathname === '/' && user && (
              <ButtonRedBlack fontWeight="bold" onClick={() => router.push(settings.dashboardRoute)}>
                Plataforma
              </ButtonRedBlack>
            )}
            {user ? (
              <>
                <SearchButton />
                <Dropdown>
                  <Avatar size="sm" src={user.user.avatar} showBorder />
                  <DropdownItems>
                    <Linker to="/perfil" >Perfil</Linker>
                    <Linker to="/sair" >Sair</Linker>
                  </DropdownItems>
                </Dropdown>
              </>
            ) : (
              <>
                <ButtonGreen fontWeight="bold" onClick={() => router.push('/login')}>Já sou membro</ButtonGreen>
                <ButtonRedBlack fontWeight="bold" onClick={() => router.push('/cadastre-se')}>Assinar agora</ButtonRedBlack>
              </>
            )}
          </CustomBox>
        </CustomFlex>
      </Box>
    </>
  )
}

export default NavBar

const CustomImage = styled(Image)`
  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    display: none;  
  }
`

const CustomFlex = styled(Flex)`
  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    flex-direction: column;
    &.open {
      position: absolute;
      right: 0;
      top: 0;
      left: 20%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.9);
      padding: 20px 30px;
      z-index: 999;
      color: #fff;
    }
  }

  @media (min-width: ${customTheme.breakpoints[2]}) {
    justify-content: flex-end
  }
`

const Icon = styled(Box)`
  @media (min-width: ${customTheme.breakpoints[2]}) {
    display: none;  
  }
  position: relative;
  z-index: 9;
  svg {
    height: 30px;
  }
`

const CustomBox = styled(Flex)<CustomBoxProps>`
  display: ${({ toggle }) => toggle ? 'flex' : 'none'}; 
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  @media (min-width: ${customTheme.breakpoints[2]}) {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: auto;
  }
  @media (min-width: ${customTheme.breakpoints[3]}) {
    justify-content: flex-end;
    span, a, button {
      margin-left: 20px;
    } 
  }

  span, a {
    cursor: pointer;
    color: ${customTheme.colors.white};
    @media (min-width: ${customTheme.breakpoints[2]}) {
      color: ${customTheme.colors.gray['700']};
    }
    font-size: ${customTheme.fontSizes.sm};
    font-weight: bold;
    transition: color .2s ease-in-out;

    &:hover {
      @media (min-width: ${customTheme.breakpoints[2]}) {
        color: ${customTheme.colors.black};
      }
      &.hasIcon {
        border-color: ${customTheme.colors.black};
      }
    }
    &.hasIcon {
      border: 1px solid  ${customTheme.colors.gray['700']};
      padding: 8px;
      border-radius: 30px;    
    }
    svg {
      width: 20px;
      display: inline-block;
      margin-right: 5px;
    }
    
  }
`

const DropdownItems = styled.div`
  @media (max-width: ${parseInt(customTheme.breakpoints[2].replace('px', ''), 10) - 1}px) {
    > a {
      display: block;
      padding: 10px 0;
      margin: 10px 0;
    }
  }
  @media (min-width: ${customTheme.breakpoints[2]}) {
    position: absolute;
    top: auto;
    right: 0;
    display: none;
    flex-direction: column;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    > a {
      margin: 0;
      padding: 5px 10px;
      font-weight: normal;
      &:hover {
        color: #fc616f;    
      }
    }
  }
`

const Dropdown = styled.div`
  position: relative;
  @media (min-width: ${customTheme.breakpoints[2]}) {
    margin-left: 15px;

    &:hover ${DropdownItems} {
      display: flex;
    }
  }
`
