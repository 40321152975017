import React from 'react'
import { Box } from '@chakra-ui/core'
import Script from 'react-load-script'
import axios from 'axios'
import { settings } from '../../config/settings'

let isAppReady = false
window.addEventListener('flutterInAppWebViewPlatformReady', function () {
  isAppReady = true
})

const MobileSambaPlayer = ({ match }):JSX.Element => {
  const idPlayer = 'player'
  let flag = false
  let flagAppUpdate = false
  let updatedInfo = false;

  const { contentId, playerKey, progress, whatched, reference, token } = match.params

  const clearPlayer = () => {
    const item = window.document.getElementById(idPlayer)
    if (item) {
      item.innerHTML = ''
    }
  }

  const dispatchActionView = async (currentProgress: number, duration: number): Promise<void> => {
    if (!JSON.parse(whatched) && contentId && !flag && currentProgress > progress && !updatedInfo) {
      flag = true
      const percent = currentProgress / (duration / 100000)
      if (Math.round(percent) > 50) {
        await axios.post(`${settings.API_URL}/user_collection_views`, {
          content: `/v1/contents/${contentId}`,
          percent: Math.round(percent) || 100,
          progress: currentProgress
        }, {
          headers: {
            Authorization: token
          }
        })
        updatedInfo = true
      }
    }
  }

  const eventListener = async (player): Promise<void> => {
    const eventParam = parseInt(player.eventParam, 10)

    switch (player.event) {
      case 'onListen':
        if (!flagAppUpdate && eventParam > 1 && eventParam % 5 === 0) {
          flagAppUpdate = true
          if (isAppReady) {
            try {
              // @ts-ignore
              window.flutter_inappwebview.callHandler('sendViewPercent', eventParam, player.duration)
            } catch (e) {
              // ignore
            }
          }
        } else if (flagAppUpdate && eventParam > 1 && (eventParam - 1) % 5 === 0) { flagAppUpdate = false }

        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          await dispatchActionView(eventParam, player.duration)
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false
        }
        break
      case 'onFinish':
        if (isAppReady) {
          // @ts-ignore
          window.flutter_inappwebview.callHandler('sendViewPercent', player.duration / 1000, player.duration)
        }
        await dispatchActionView(player.duration / 1000, player.duration)
        break
      default: break
    }
  }

  const init = () => {
    const playerParams = {
      enableShare: false,
      resume: process
    }
    clearPlayer()
    // eslint-disable-next-line no-new
    new (window as typeof globalThis).SambaPlayer(idPlayer, {
      ph: playerKey,
      m: reference,
      playerParams,
      events: {
        '*': eventListener
      }
    })
  }

  return (
    <Box pos="relative" overflow="hidden" w="100%" h="100vh">
      <Script url="https://player.sambatech.com.br/v3/samba.player.api.js" onLoad={init} />
      <Box pos="absolute" left={0} right={0} bottom={0} top={0} w="100%" id={idPlayer} />
    </Box>
  )
}

export default MobileSambaPlayer
