import React from 'react'
import customTheme from '../../../../styles/theme'
import styled from '@emotion/styled/macro'
import { Flex, HeadingProps, Image, PseudoBox } from '@chakra-ui/core'
import { Typography } from '../../foundation'
import { priceFormat } from '../../../../utils/helpers/price'
import { useHistory } from 'react-router'
import { addStorePlanSession } from '../../../../utils/helpers/store-plan-session'
import { goToUrl } from '../../../../utils/helpers/go-to-url'

type PlantItemProps = {
  dark?: boolean
  content?: any
  trial?:boolean
  data: {
    image?: string
    description?: string
    title: string
    totalPrice?: number
    selectable?: boolean
    url?: string
    intervalCount?: number
    planChange?: boolean
  }
}

const CardPlanItem = ({ dark, content, trial, data }: PlantItemProps): JSX.Element => {
  const history = useHistory()
  const intervalCount = data.intervalCount || 1

  const goToPay = async () => {
    try {
      if (content) {
        const metaInfo = {
          redirectTo: data.planChange ? '/confirmar-alterar-plano' : '/pagamento',
          data: data.planChange ? { ...content, '@id': `/v1/plans/${content.id}` } : content
        }
        await addStorePlanSession(metaInfo.data, metaInfo.data?.selectable)
        if (trial) {
          goToUrl(history, '/pagamento-trial')
        } else {
          goToUrl(history, metaInfo.redirectTo)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <BoxCustom
      bg={dark ? 'gray.700' : 'white'}
      color={!dark ? 'gray.700' : 'white'}
      p={6}
      borderRadius="md"
      boxShadow="1px 1px 20px 0 rgba(0, 0, 0, 0.15)"
      onClick={goToPay}
    >
      <Flex pos="relative" zIndex={2} flexDir="column" h="100%" justifyContent="space-between">
        <CycleImage>
          <CycleImageWrap>
            {data.image && <Image src={data.image} ignoreFallback alt={data.title} />}
          </CycleImageWrap>
        </CycleImage>

        <Typography type="xl" as="h3" mb={4} textAlign="center" textTransform="uppercase">{data.title}</Typography>
        <Description dark={dark} type="sm" as={'div'} mb={0} dangerouslySetInnerHTML={{ __html: data.description }} />
        <CustomPrice>
          <Typography type="3xl" as="h4" mt={4} mb={0} textAlign={intervalCount > 1 ? 'left' : 'center'}>
            {intervalCount > 1 && (<span className="in">Em até</span>)}
            {(!data.selectable && data.totalPrice) && priceFormat(data.totalPrice / intervalCount)}
            {intervalCount > 1 && (!data.selectable && data.totalPrice) && (<span className="by">/mês</span>)}
          </Typography>
        </CustomPrice>
      </Flex>
    </BoxCustom>
  )
}

export default CardPlanItem

const CycleImage = styled.div`
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  background: #fff;
  text-align: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`

const CycleImageWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 10px;
  margin: 0;
  background: ${customTheme.colors.white};
  transition: background .2s ease-in-out, margin .3s ease, padding .5s ease-in-out;
`

type DescriptionProps = HeadingProps & {
  type?: '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  dark?: boolean
}
const Description = styled(Typography)<DescriptionProps>`
  ul {
    list-style: none;

    li {
      margin-bottom: 8px;

      &:before {
        content: "✔";
        padding-right: 7px;
        color: ${({ dark }) => dark ? customTheme.colors.white : customTheme.colors.green['700']};
      }
    }
  }
`

const CustomPrice = styled.div`
  .in {
    display: block;
    text-align: left;
    font-size: 18px;
    font-weight: normal;
  }

  .by {
    font-size: 16px;
  }
`

const BoxCustom = styled(PseudoBox)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    top: -100%;
    margin-left: -200px;
    left: 50%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: ${customTheme.colors.green['700']};
    transition: top .5s ease;
  }

  &:hover {
    &::before {
      top: -75%;
    }
    
    ${CycleImageWrap} {
      background-color: ${customTheme.colors.pink['100']};
      padding: 12px;
      margin: 7px;
    }
  }
`
