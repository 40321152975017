import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Button } from '@chakra-ui/core'

const PopupMessage = (props): JSX.Element | null => {
  const [disabled, setDisabled] = useState<any>(false)
  const closePopUp = () => {
    setDisabled(true)
  }

  return (
    (!props.disabled)
      ? <PopUpBox className={!disabled ? 'fadeIn' : 'fadeOut'}>
        <PopUpBg/>
        <PopUpContent style={{ width: `${props?.width || 700}px`, height: `${props?.height || 400}px` }}>
          <CloseButton onClick={closePopUp}>x</CloseButton>
          <div style={{ padding: 50 }} dangerouslySetInnerHTML={{ __html: props?.content }}/>
          <Box textAlign="center" mt={4}>
            <Button h={12}
              px={12}
              bg="green.700"
              color="white"
              fontSize="md"
              _hover={{
                bg: 'green.700',
                color: 'gray.700'
              }} type="button" onClick={closePopUp}>
                Vamos lá!
            </Button>
          </Box>
        </PopUpContent>
      </PopUpBox>
      : null
  )
}

export default PopupMessage

const PopUpBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

const PopUpBg = styled.div`
  background: rgb(0 0 0 / 70%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
`

const PopUpContent = styled.div`
  background: #fff;
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  padding: 10px;
  box-shadow: 3px 3px 6px #5e5e5e;
  justify-content: center;
  align-items: center;
  white-space:pre-wrap;
  border-radius: 20px;
`

const CloseButton = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  font-family: monospace;
  width: 30px;
  background: #ddd;
  line-height: 26.5px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  z-index: 99;
  display: block;
`
