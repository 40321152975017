import React, { useEffect } from 'react'
import { Auth, I18n } from 'aws-amplify'
import { Box, Flex } from '@chakra-ui/core'
import { ButtonRedGreen, Typography } from '../../component/ui/foundation'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Input, InputPassword } from '../../component/form-elements'
import {
  AlertBoxContextInterface,
  useAlertBox
} from '../../component/ui/layout/alert-box/alert-box-context'
import { useHistory } from 'react-router'
import { goToUrl } from '../../utils/helpers/go-to-url'
import { exceptionsError } from '../../utils/helpers/cognito-erros'
import { StepsDots } from '../../component/ui/layout'
import { Link, useLocation } from 'react-router-dom'
import InputPhoneMasked from '../../component/form-elements/phone-mask'
import { settings } from '../../config/settings'
import PopupMessage from './pages/popup-message'
import { getSopPlans } from '../../utils/helpers/sop-plans'

const SignUp = () => {
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const location = useLocation()
  const history = useHistory()
  const skipPlan = new URLSearchParams(location.search).get('sp')
  const campaignValue = new URLSearchParams(location.search).get('utm_campaign')
  /*
      Show only product
      Flavio Lacerda 12/11/2024
     */
  const sop = new URLSearchParams(location.search).get('sop')
  const popupValues = {
    width: 500,
    height: 450,
    disabled: (campaignValue !== 'satisfacao30'),
    content: '<b>Campanha Satisfação garantida, ou seu dinheiro de volta!</b><br /><br />\n' +
            'Você tem até 30 dias após a data da sua assinatura para fazer o cancelamento e pedir a restituição do valor investido. Após essa data, o cancelamento acontecerá sem restituição financeira.'
  }
  const initialValues = {
    firstName: new URLSearchParams(location.search).get('nome') || '',
    lastName: '',
    phone: new URLSearchParams(location.search).get('cel') || '',
    code: '55',
    email: new URLSearchParams(location.search).get('email') || '',
    password: '',
    confirmPassword: '',
    trial: (location.pathname.includes('trial'))
  }

  useEffect(() => {
    document.title = 'GAIZ - Inscreva-se na plataforma'
  })
  const onSubmit = async (values) => {
    try {
      const { password } = values
      const email = values.email.toLowerCase()
      const name = `${values.firstName} ${values.lastName}`
      const phone = values.phone.replace(/\D/g, '')

      /**
             * criação de contas no cognito
             */
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: name,
          phone_number: phone ? `+${values.code ? values.code : '55'}${phone}` : ''
        }
      })

      // alertBox(I18n.get('AlertSendCode'))
      // goToUrl(history, '/confirmar-usuario', { email, password })
      /**
             * Faz login no cognito
             */
      await Auth.signIn(email, password)
      if (sop && getSopPlans(sop) != null) {
        goToUrl(history, '/escolha-seu-plano', { sop: getSopPlans[sop] })
      } else if (values.trial) {
        if (skipPlan !== null) {
          goToUrl(history, '/pagamento-trial', { skipPlan: true })
        } else {
          goToUrl(history, '/escolha-seu-plano', { trial: true })
        }
        // goToUrl(history, '/escolha-seu-plano', { trial: true })
      } else {
        goToUrl(history, settings.dashboardRoute)
      }
    } catch (error) {
      alertBox(exceptionsError(error.code, error.message))
    }
  }

  return (
    <>
      <PopupMessage {...popupValues} />
      <StepsDots currentDot={1}/>

      <Box textAlign="center">
        <Typography type="4xl" as="h2" textTransform="uppercase">Inscreva-se</Typography>
        <Typography type="md" as="p" mb={8}>Leva apenas quatro etapas para iniciar seu teste gratuito.
                    Inscreva-se com sua conta social ou crie uma conta conosco.</Typography>

        <Flex mb={8} alignItems="center" justifyContent="center">
          <Box h="1px" bg="gray.200" w="25%"/>
          <Typography type="md" as="p" mb={0} mx={4}>OU CRIE UMA CONTA</Typography>
          <Box h="1px" bg="gray.200" w="25%"/>
        </Flex>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            firstName: Yup.string().required(I18n.get('ValidateRequiredField')),
            lastName: Yup.string().required(I18n.get('ValidateRequiredField')),
            phone: Yup.string().required(I18n.get('ValidateRequiredField')),
            email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField')),
            password: Yup.string()
              .required(I18n.get('ValidateRequiredField'))
              .min(8, I18n.get('ValidateMinPassword'))
              .max(24, I18n.get('ValidateMaxPassword'))
              .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
              .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
              .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
              .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar')),
            confirmPassword: Yup.string().when('password', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('password')], I18n.get('ValidatePasswordNotEquals'))
                .required(I18n.get('ValidateRequiredField'))
            })
          })
        }
        onSubmit={onSubmit}
      >
        {(actions) => (
          <Form>
            <Input title="Primeiro nome" name="firstName" {...actions} />
            <Input title="Último nome" name="lastName" {...actions} />
            <InputPhoneMasked title="Celular" name="phone" nameCode="code" {...actions} />
            <Input title="Email" name="email" {...actions} lowercase/>
            <InputPassword title="Senha" name="password" {...actions} />
            <InputPassword title="Confirmar senha" name="confirmPassword" {...actions} />

            <Box textAlign="center" mt={4}>
              <ButtonRedGreen
                type="submit"
                isLoading={actions.isSubmitting}
                loadingText="Registrando"
                isDisabled={actions.isSubmitting}
              >
                                Cadastre-se
              </ButtonRedGreen>
            </Box>
          </Form>
        )}
      </Formik>

      <Flex justifyContent={'space-around'} mt={4}>
        <Box><Link to="/login">Fazer login</Link></Box>
      </Flex>
    </>
  )
}

export default SignUp
