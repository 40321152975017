import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { Typography } from '../../component/ui/foundation'
import { CardPlanItem } from '../../component/ui/layout'
import { PlanModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { makeChangeablePlans } from '../../main/factories/usecases/subscriptions/make-plans'

const ChangeSelectPlan = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<PlanModel[]>(null)

  useEffect(() => {
    (async () => {
      try {
        const content = await makeChangeablePlans()
        setData(content)
        setLoading(false)
      } catch (e) {
        setData([])
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <Loading/>
  }

  return (
    <Box py={8}>
      <Box textAlign="center" w={['100%', '', '70%']} mx="auto" mb={12}>
        <Typography type="4xl" as="h2" mb={4}>Selecione um plano</Typography>
        <Typography type="sm" as={'p'} mb={8}>Escolha o plano que mais combina com você</Typography>
      </Box>

      <Flex flexWrap="wrap" mb={16} mx={-2} justifyContent="center" flexShrink={3}>
        {data.map((planItem, index) => (
          <Box key={planItem['@id']} w={['100%', '50%', '33%', '25%']} px={2} mb={4}>
            <CardPlanItem dark={index === 0} content={planItem} data={{
              description: planItem.description,
              title: planItem.name,
              image: planItem.image,
              selectable: planItem.selectable,
              totalPrice: planItem.totalPrice,
              url: `/alterar-plano/${planItem.id}`,
              intervalCount: planItem.intervalCount,
              planChange: true
            }} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default ChangeSelectPlan
