import { allTrainingToView } from '../../../../data/usecases/contents-to-view/all-training-to-view'
import { FolderContentModel } from '../../../../domain/models'
import { AxiosResponse } from 'axios'
import { api } from '../../../../infra/http/remote'

type FilterCustomerFolderProps ={
  folder?: string
  search?: string
  perPage?: 30
}

export const makeCurrentCustomerFolderContents = async (
  params?: FilterCustomerFolderProps
): Promise<FolderContentModel[]> => {
  try {
    const response: AxiosResponse = await api.get('/current_customer_folder_contents', {
      params: {
        ...params
      }
    })

    return allTrainingToView.build(response.data['hydra:member'])
  } catch (error) {
    return []
  }
}
