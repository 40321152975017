import { settings } from '../../config/settings'
import { Auth } from 'aws-amplify'
import { clearStorage } from '../../utils/helpers/clear-storage'
import { api } from '../../infra/http/remote'
import { AxiosResponse } from 'axios'
import React, { useEffect } from 'react'

const SignInAuth: React.FC = () => {
  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentUserInfo()
        const apiResponse = await api.post<AxiosResponse>('../auth', response)
        if (apiResponse.data.toString() === 'ok') {
          window.location.href = settings.dashboardRoute
        } else {
          clearStorage()
          if (response.attributes.email === '' || typeof response.attributes.email === 'undefined') { window.location.href = '/login?e=s2' } else { window.location.href = '/login?e=s1' }
        }
      } catch (e) {
        clearStorage()
      }
    })()
  }, [])

  return (
    <></>
  )
}

export default SignInAuth
