interface HashMap<T> {
    [key: string]: T;
}

const hashPlans: HashMap<number[]> = {
  carolnogaiz: [17, 18, 19],
  pdal: [14, 15, 16],
  cp: [11, 12, 13]
}

export const getSopPlans = (sop?: string) => {
  if (sop === null || typeof sop === 'undefined') return null
  return hashPlans[sop]
}
