import React from 'react'
import { I18n } from 'aws-amplify'
import { Box, Flex, Grid } from '@chakra-ui/core'
import { ButtonRedGreen, Container } from '../../component/ui/foundation'
import NavProfile from '../../component/nav-profile/nav-profile'
import { getUser, setUser } from '../../utils/helpers/user'
import AvatarUpload from '../../component/avatar-upload/avatar-upload'
import { Form, Formik } from 'formik'
import { Input } from '../../component/form-elements'
import * as Yup from 'yup'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'
import { updateUserDatabase } from '../../main/factories/usecases/user/user'
import InputPhoneMasked from '../../component/form-elements/phone-mask'
import parsePhoneNumber from 'libphonenumber-js'

const Profile = () => {
  const { alertBox } = useAlertBox()
  const user = getUser()

  const getCodePhone = (phone) => {
    const parsePhone = parsePhoneNumber(`+${phone}`)
    if (phone) { return (parsePhone && parsePhone.countryCallingCode) ? parsePhone.countryCallingCode.toString() : '55' } else { return '55' }
  }

  const getPhoneWithoutCode = (phone) => {
    const parsePhone = parsePhoneNumber(`+${phone}`)
    if (phone) { return (parsePhone && parsePhone.nationalNumber) ? parsePhoneNumber(`+${phone}`).nationalNumber.toString() : phone } else { return '' }
  }

  const initialValues = {
    name: user.user.name || '',
    code: getCodePhone(user.profile.phoneNumber) || '55',
    phone: getPhoneWithoutCode(user.profile.phoneNumber) || '',
    email: user.user.email || ''
  }

  const onSubmit = async (val) => {
    try {
      const body = {
        ...val,
        phone: `${val.code}${val.phone}`.replace(/\D/g, '')

      }
      const { data } = await updateUserDatabase(body, user)
      const updatedUser = {
        ...user,
        profile: { ...user.profile, phoneNumber: data.phoneNumber }
      }
      await setUser(updatedUser)
    } catch (e) {
      alertBox('Erro ao salvar os dados, tente mais tarde')
    }
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/perfil'} />
          </Box>
          <Box>
            <Flex flexWrap="wrap">
              <Box w={['100%', '100%', '20%']}>
                <AvatarUpload user={user}/>
              </Box>
              <Box w={['100%', '100%', '40%']}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    Yup.object().shape({
                      name: Yup.string().required(I18n.get('ValidateRequiredField')),
                      phone: Yup.string().required(I18n.get('ValidateRequiredField')),
                      email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField'))
                    })
                  }
                  onSubmit={onSubmit}
                >
                  {(actions) => (
                    <Form>
                      <Input title="Nome" name="name" defaultValue={initialValues.name} {...actions} />
                      <InputPhoneMasked
                        title="Celular"
                        name="phone"
                        nameCode="code"
                        {...actions}
                        defaultCode={initialValues.code}
                        defaultValue={initialValues.phone}
                      />

                      <Input disabled={true} title="Email" name="email" defaultValue={initialValues.email} {...actions} />

                      <ButtonRedGreen
                        type="submit"
                        isLoading={actions.isSubmitting}
                        loadingText="Registrando"
                        isDisabled={actions.isSubmitting}
                      >
                        SALVAR
                      </ButtonRedGreen>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Profile
