import React, { useEffect, useState } from 'react'
/* import LoadBlocks from '../component/load-blocks/load-blocks'
import { PageResultModel } from '../domain/models'
import { makePage } from '../main/factories/usecases/page/page' */
import Loading from '../component/loading/loading'
import { Auth } from 'aws-amplify'
import { goToUrl } from '../utils/helpers/go-to-url'
import { settings } from '../config/settings'
import { useHistory } from 'react-router'

const Home = () => {
  const history = useHistory()

  async function startHome () {
    try {
      await Auth.currentSession()
      goToUrl(history, settings.dashboardRoute)
    } catch (e) {
      // not logged
      if (window.location.hostname.includes('carol')) {
        window.location.replace('https://carol.gaiz.app')
      } else {
        window.location.replace('https://fitness.gaiz.app')
      }
    }
  }

  useEffect(() => {
    startHome().then()
  }, [])
  return <Loading/>
  /*
    const [content, setContent] = useState<PageResultModel>(null)
    useEffect(() => {
      (async () => {
        try {
          const data = await makePage('homepage')
          setContent(data)
        } catch (e) {
          throw new Error('Ocorreu um erro ao processar a página')
        }
      })()
    }, [])

    if (!content) {
      return <Loading />
    }

    return <LoadBlocks containers={content.containers} locale="main" /> */
}

export default Home
